import React from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
import HomeHeader from "./HomeHeader";
import AboutUs from "../About/About";
import Features from "../features/Features";
import RequestQuote from "../quote/RequestBlog";
import TeamMembers from "../team/TeamMembers";
import LatestBlog from "../blog/LatestBlog";

const Home = () => {
  // useEffect(() => {
  //   // Initialize AOS with your desired settings
  //   AOS.init({
  //     duration: 2000, // Animation duration in milliseconds
  //     delay: 0, // Delay before the animation starts
  //     easing: "ease", // Easing function
  //     once: true, // Whether the animation should occur only once
  //   });
  // }, []);

  return (
    <div>
      <HomeHeader />

      {/* Apply data-aos="zoom-in" to each section */}
      <div data-aos="fade-down" data-aos-delay="100">
        <AboutUs />
      </div>

      <div data-aos="zoom-in-up" data-aos-delay="200">
        <Features />
      </div>

      <div data-aos="zoom-in-up" data-aos-delay="300">
        <RequestQuote />
      </div>

      <div data-aos="zoom-in-up" data-aos-delay="400">
        <TeamMembers />
      </div>

      <div data-aos="zoom-in-up" data-aos-delay="500">
        <LatestBlog />
      </div>

      {/* Uncomment and apply data-aos as needed to other sections */}
      {/* <div data-aos="zoom-in-up">
        <Testimonial />
      </div> */}
      {/* <div data-aos="zoom-in">
        <FactsSection />
      </div> */}
      {/* <div data-aos="zoom-in">
        <Statistics />
      </div> */}
    </div>
  );
};

export default Home;
