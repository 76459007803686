import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import PageRoute from "./Pages/PageRoute";
import "./App.css";
import "./animate/animate.css";
import "./animate/animate.min.css";
import Loader from "./Screens/Loader/Loader";
import ReactGA from "react-ga";

const App = () => {
  const TRACKINGID = "G-BNNFXGD8FS";
  useEffect(() => {
    // Initialize AOS with your desired settings
    AOS.init({
      duration: 2000, // Animation duration in milliseconds
      delay: 0, // Delay before the animation starts
      easing: "ease", // Easing function
      once: true, // Whether the animation should occur only once
    });
  }, []);

  // ReactGA.send({ hitType: "pageview", page: "/" })
  // ReactGA.pageview(document.location.pathname)
  useEffect(() => {
    ReactGA.initialize(TRACKINGID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [loading, setLoading] = useState(true);

  // Simulate loading process with useEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Change the delay as needed or remove this setTimeout

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={44}
        // color="47, 68, 84"
        // color="181, 43, 78"
        color="214, 81, 82"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.4}
      /> */}

      {loading ? (
        <Loader />
      ) : (
        <div>
          {" "}
          <PageRoute />
        </div>
      )}
    </div>
  );
};

export default App;
